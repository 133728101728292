<template>
    <GridItem :colidx="col" class="p-0 m-0 top" transparent>
        <GridItem class="small" :colidx="col" :height="height">
            <div class="clickable-area" @click="clickevt(event)" />

            <div
                v-if="loaded"
                class="gradient-overlay"
                :style="
                    elevated && event.public === false
                        ? ''
                        : 'background: linear-gradient(-35deg, ' +
                          event.rgba +
                          ' 0%, rgba(25, 22, 32,0) 100%);'
                "
            />
            <div v-if="loaded" class="calendar-container">
                <figure class="hero-img" v-if="event.image">
                    <nuxt-img
                        loading="lazy"
                        :src="event.image"
                        :alt="event.title"
                    />
                </figure>
                <GridBody class="card-body">
                    <div class="card-block">
                        <div
                            v-if="elevated"
                            class="flex-between fixed-top-left"
                        >
                            {{ event.featured ? "🌟" : "" }}
                        </div>

                        <div class="flex-between">
                            <!-- {{ event.featured ? "🌟" : "" }} -->

                            <span
                                class="is-size-6 m-0 p-0 is-uppercase has-text-weight-bold is-subtle"
                            >
                                {{
                                    hasDay
                                        ? new Date(
                                              event.start
                                          ).toLocaleDateString($i18n.locale, {
                                              weekday: "short",
                                              month: "short",
                                              day: "numeric",
                                              //   hour: "numeric",
                                              //   minute: "numeric",
                                              //   hour12: true,
                                              //   timeZone: "UTC",
                                              //   timeZoneName: "short",
                                              //   formatMatcher: "best fit",
                                              //   hourCycle: "h24",
                                          })
                                        : ""
                                }}
                                {{ event.startString }}
                                {{
                                    event.category?.value === "courses"
                                        ? ` - ${event.endString}`
                                        : ""
                                }}
                            </span>

                            <div
                                v-if="
                                    elevated &&
                                    // event.start > new Date().toISOString() &&
                                    event.paid &&
                                    event.seats &&
                                    event.seats - event.seatsTaken === 0
                                "
                            >
                                <span
                                    class="total-label is-size-6 m-0 p-0 is-uppercase has-text-weight-bold has-text-danger"
                                    >{{ $t("event.soldout") }}</span
                                >
                            </div>
                            <div
                                v-if="
                                    elevated &&
                                    // event.start > new Date().toISOString() &&
                                    event.paid &&
                                    event.seats &&
                                    event.seats - event.seatsTaken > 0
                                "
                            >
                                <span
                                    class="total-label is-size-6 m-0 p-0 is-uppercase has-text-weight-bold has-text-success"
                                >
                                    <ICountUp
                                        v-if="
                                            // loadedEvent.seatsTaken &&
                                            event.seats
                                        "
                                        :delay="500"
                                        :startVal="event.seats"
                                        :endVal="event.seats - event.seatsTaken"
                                        :options="seatsOptions"
                                        @ready="onReady"
                                    />
                                    {{ $t("event.shortSeats") }}</span
                                >
                            </div>
                        </div>

                        <div v-if="elevated" class="flex-between">
                            <div
                                class="is-size-6 is-uppercase has-text-weight-bold is-primary-alt"
                            >
                                <span
                                    class="total-label is-size-6 m-0 p-0 is-uppercase has-text-weight-bold has-text-danger"
                                >
                                    <b-icon
                                        v-if="event.public"
                                        icon-pack="fas"
                                        icon="fas fa-globe"
                                        class="has-text-success" />
                                    <b-icon
                                        v-if="!event.public"
                                        icon-pack="fas"
                                        icon="fas fa-lock"
                                        class="is-danger"
                                /></span>
                                <!-- {{ !event?.needsMembership ? "🎫" : "" }} -->
                            </div>
                            <div v-if="elevated" class="flex-end">
                                <div
                                    class="is-size-6 is-uppercase has-text-weight-bold is-subtle"
                                ></div>

                                <div
                                    class="ml-3 is-size-6 is-uppercase has-text-weight-bold is-subtle"
                                    v-if="event?.paid"
                                >
                                    🎟️{{ event.currency }}{{ event.price }}
                                </div>
                            </div>
                        </div>
                        <h3
                            class="name has-text-weight-bold mt-1 is-size-4 is-uppercase"
                            :style="'color:' + event.backgroundColor + ';'"
                        >
                            {{ event.title }}
                        </h3>
                        <h3
                            v-if="hasCategory"
                            class="category has-text-weight-bold is-size-7 is-uppercase is-subtle"
                        >
                            {{
                                $i18n.locale === "it"
                                    ? event.category.name
                                    : event.category.nameEn
                            }}
                        </h3>
                    </div>
                    <div class="card-block">
                        <p
                            v-html="event.summary"
                            v-if="event.summary && event.featured"
                            class="is-size-6 mt-2 summary-box"
                        />
                        <p
                            v-html="event.summary"
                            v-if="
                                (event.summary && event.featured === false) ||
                                (event.summary && !event.featured)
                            "
                            class="is-size-6 mt-2 short-summary-box"
                        />
                    </div>
                </GridBody>
            </div>
            <div v-else class="calendar-container">
                <figure class="hero-img">
                    <b-skeleton
                        as="div"
                        width="100%"
                        height="100%"
                        animated
                        :count="1"
                    />
                </figure>
                <!-- <nuxt-img
                                    loading="lazy"
                                    :src="event.image"
                                    :alt="event.title"
                                /> -->
                <!-- </figure> -->

                <GridBody class="card-body">
                    <b-skeleton
                        as="div"
                        width="40%"
                        height="1rem"
                        animated
                        :count="1"
                    />

                    <b-skeleton
                        as="div"
                        width="100%"
                        height="2rem"
                        animated
                        :count="1"
                    />
                    <b-skeleton
                        as="div"
                        width="80%"
                        height="0.75rem"
                        animated
                        :count="1"
                    />

                    <p
                        class="subtitle is-size-6 is-text has-text-weight-medium mt-2 is-subtle summary-box"
                    >
                        <b-skeleton
                            as="div"
                            width="100%"
                            height="1rem"
                            animated
                            :count="1"
                        />
                        <b-skeleton
                            as="div"
                            width="70%"
                            height="1rem"
                            animated
                            :count="1"
                        />
                    </p>
                </GridBody>
            </div>
        </GridItem>
        <!-- <GridItem
            colored
            bgrgba="55, 51, 112"
            glassy
            v-if="elevated && event?.category?.value === 'events'"
            class="small mt-3 p-2"
            :colidx="col"
            :height="height"
        >
            <div class="clickable-area" @click="clickevtlists(event)" />
            <h3
                class="name has-text-weight-bold mt-1 is-size-6 is-uppercase is-primary"
            >
                <b-icon icon-pack="fas" icon="fas fa-list mb-1" />

                {{ $t("manageevents.lists") }}
                {{
                    event?.entryLists?.length > 0
                        ? `(${event?.entryLists?.length})`
                        : ""
                }}
            </h3>
        </GridItem> -->
    </GridItem>
</template>
<style lang="scss" scoped>
.top {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.name {
    line-height: 1.125;
}
.clickable-area {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    cursor: pointer;
}
.calendar-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    width: 100%;
    height: 100%;
    /* grid-auto-rows: 1fr; */
}
.gradient-overlay {
    transition: all 0.2s ease-in-out;
    opacity: 0.6;
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    /* background: red; */
    border-radius: var(--radius-xs);
}
.summary-box {
    /* max-height: 3rem; */
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5;
    color: var(--color-primar-alt);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-clamp: 5;
    -webkit-line-clamp: 5;
    box-orient: vertical;
    -webkit-box-orient: vertical;
    white-space: pre-line;
    width: 100%;
}
.short-summary-box {
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-clamp: 1;
    margin: 0;
    -webkit-line-clamp: 1;
    box-orient: vertical;
    -webkit-box-orient: vertical;
    white-space: pre-line;
}
.flex {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.flex-end {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.flex-between {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.small {
    transition: all 0.2s ease-in-out;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    cursor: pointer;
    height: auto;
    width: 100%;
    min-width: 100%;
    overflow: hidden;
    @media (max-width: 768px) {
        .gradient-overlay {
            opacity: 0.8;
        }
        .hero-img {
            filter: brightness(1.3) saturate(1) blur(0.25rem);
            mask-image: linear-gradient(
                110deg,
                rgba(0, 0, 0, 0.6) 0%,
                /* rgba(0, 0, 0, 0.02) 50%, */ rgba(0, 0, 0, 0) 75%
            );
        }
    }
    &:hover {
        /* transform: scale(1.001); */
        .gradient-overlay {
            opacity: 0.8;
        }
        .hero-img {
            filter: brightness(1.3) saturate(1) blur(0.25rem);
            mask-image: linear-gradient(
                110deg,
                rgba(0, 0, 0, 0.6) 0%,
                /* rgba(0, 0, 0, 0.02) 50%, */ rgba(0, 0, 0, 0) 75%
            );
        }
    }
    /* width: 100%; */
    .b-skeleton {
        .b-skeleton-item {
            background: linear-gradient(
                90deg,
                rgba(var(--bg-secondary-rgba), 1) 25%,
                rgba(var(--color-primary-alt-rgba), 0.15) 50%,
                rgba(var(--color-primary-alt-rgba), 0.4) 60%,
                rgba(var(--bg-secondary-rgba), 1) 75%
            );
            background-size: 400% 100%;
        }
    }
    .card-body {
        margin-left: 20%;
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: stretch;
        height: 100%;
        /* width: 100%; */
    }
    .hero-img {
        transition: all 0.2s ease-in-out;
        width: 100%;
        height: 100%;
        .b-skeleton {
            min-height: 200px;

            .b-skeleton-item {
                min-height: 200px;

                border-radius: var(--radius-xs) !important;
            }
            /* margin: 0.5rem 0; */
        }
    }
}
.title {
    font-size: 2rem;
    font-weight: 700;
    color: var(--color-primary);
}
.subtitle {
    font-size: 1.5rem;
    font-weight: 700;
    color: var(--color-subtle);
    margin: 0;
}
.fixed-top-left {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    padding: 0.5rem;
}
.hero-img {
    // prevent image dragging

    z-index: 1;
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    height: 100%;
    filter: brightness(1.2) saturate(1.2) blur(0.25rem);
    /* max-height: 100%; */
    width: 40%;
    max-width: 40%;
    /* border-radius: var(--radius-sm); */
    overflow: hidden;
    transform: scale(1.1);
    // apply a gradient trasparency filter to the image container
    mask-image: linear-gradient(
        110deg,
        rgba(0, 0, 0, 0.2) 0%,
        /* rgba(0, 0, 0, 0.01) 70%, */ rgba(0, 0, 0, 0) 80%
    );
    img {
        /* aspect-ratio: 1 / 1; */

        /* width: 100%; */
        width: 100%;
        height: 100%;
        /* min-height: 240px; */
        object-fit: cover;
        // center
        object-position: center;
    }
}
</style>
<script>
import GridItem from "~/components/GridItem";
import GridBody from "~/components/GridBody";
import ICountUp from "vue-countup-v2";

export default {
    name: "EventCard",
    data() {
        return {};
    },
    components: {
        GridItem,
        GridBody,
        ICountUp,
    },
    props: {
        height: {
            type: String,
            default: "auto",
        },
        event: {
            type: Object,
            required: true,
        },
        col: {
            type: Number,
            required: true,
        },
        seatsOptions: {
            type: Object,
            required: true,
        },
        onReady: {
            type: Function,
            required: true,
        },
        clickevt: {
            type: Function,
            // required: true,
            default: () => {},
        },
        clickevtlists: {
            type: Function,
            // required: true,
            default: () => {},
        },
        loaded: {
            type: Boolean,
            default: false,
        },
        hasDay: {
            type: Boolean,
            default: false,
        },
        startOnly: {
            type: Boolean,
            default: false,
        },
        elevated: {
            type: Boolean,
            default: false,
        },
        hasCategory: {
            type: Boolean,
            default: true,
        },
    },
    methods: {},
};
</script>

