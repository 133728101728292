<template>
    <div class="grid-body" slot="grid-body">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "GridBody",
    components: {},
};
</script>

<style lang="scss" scoped>
.grid-body {
    z-index: 1;
    display: flex;
    width: 100%;
    /* height: 100%; */
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: 1rem;
}
</style>