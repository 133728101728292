<template>
    <div class="grid-container" slot="grid-container">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "GridBox",
    components: {
        // IconSystem,
        // IconLight,
        // IconDark,
        // IconSepia,
    },
};
</script>

<style lang="scss" scoped>
.grid-container {
    width: 100%;
    min-width: 100%;
    display: grid;
    grid-gap: 1rem;
    padding-bottom: 1rem;
    /* max-width: 90vw; */
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    grid-template-rows: repeat(auto-fill, minmax(1rem, 1fr));
    /* justify-items: center;
    align-items: center; */
    justify-content: center;
    align-content: center;
    max-width: 1280px;
    @media (max-width: 960px) {
        grid-template-columns: repeat(1, minmax(320px, 1fr));
        display: flex;
        flex-direction: column;
    }
    /* grid-auto-rows: 1fr; */
}
</style>