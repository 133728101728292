<template>
    <div
        class="grid-item"
        :class="{
            glassy: glassy,
            transparent: transparent,
            np: np,
            colored: colored,
        }"
        slot="grid-item"
        :style="cssProps"
    >
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "GridItem",
    components: {},
    props: {
        size: {
            type: String,
            default: "100%",
        },
        height: {
            type: String,
            default: "100%",
        },
        colidx: {
            type: Number,
            default: 1,
        },
        rowIdx: {
            type: Number,
            default: 1,
        },
        glassy: {
            type: Boolean,
            default: false,
        },
        transparent: {
            type: Boolean,
            default: false,
        },
        np: {
            type: Boolean,
            default: false,
        },
        bg: {
            type: String,
            default: "transparent",
        },
        bgrgba: {
            type: String,
            default: "0,0,0",
        },
        colored: {
            type: Boolean,
            default: false,
        },
    },
    // data: function () {
    //     return {
    //         varSize: this.size,
    //         varHeight: this.height,
    //     };
    // },
    computed: {
        cssProps() {
            return {
                "--grid-width": this.size,
                "--grid-height": this.height,
                "--col": this.colidx,
                "--row": this.rowIdx,
                "--bg-color": this.bg,
                "--bg-color-rgba": this.bgrgba,
            };
        },
    },
};
</script>

<style lang="scss" scoped>
.np {
    padding: 0 !important;
    margin: 0 !important;
}
.colored {
    background: var(--bg-color) !important;
}
.colored.glassy {
    background: rgba(var(--bg-color-rgba), 0.85) !important;
    backdrop-filter: blur(2rem) !important;
}
.glassy {
    background: rgba(var(--bg-secondary-rgba), 0.85) !important;
    backdrop-filter: blur(2rem) !important;
}
.transparent {
    background: none !important;
    backdrop-filter: none !important;
}
.grid-item {
    position: relative;
    border-radius: var(--radius-xs);
    /* overflow: hidden; */
    background: var(--bg-secondary);
    /* background: rgba(var(--bg-secondary-rgba), 0.8); */
    /* backdrop-filter: blur(2rem) !important; */

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    transition: 0.25s;
    padding: 1rem;
    padding-right: 1.5rem;
    z-index: 1;
    /* max-height: 200px; */
    grid-column: span var(--col);
    grid-row: span var(--row);
    width: var(--grid-width);
    height: var(--grid-height);
    /* @media (max-width: 768px) {
        padding: 1rem;
    } */

    /* height: var(--grid-height); */
}
</style>